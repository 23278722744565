import React, { useEffect, Suspense, lazy, useContext } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import '@splidejs/react-splide/css';
import { hotjar } from 'react-hotjar';

import Topbar from './components/Topbar/Topbar';
import Footer from './components/Footer/Footer';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Dashboard from './Pages/Dashboard';
import DashboardDetail from './Pages/Dashboard/detail';
import DashboardProjects from './Pages/Dashboard/projects';
// import AuthView from './Pages/Auth';
// import ResetPass from './Pages/Auth/resetPass';
// import VerifyReset from './Pages/Auth/verifyReset';
// import SuscriptionModel from './Pages/SuscriptionModel';
import Instalments from './Pages/Instalments';
import Payment from './Pages/Payment';
import Invest from './Pages/Payment/Invest';
import PersonalData from './Pages/Payment/PersonalData';
import Complete from './Pages/Payment/Complete';
import Error from './Pages/Payment/Error';
import SummaryInvest from './Pages/Payment/SummaryInvest';
import Identity from './Pages/Payment/Identity';
import Contract from './Pages/Payment/Contract';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { on } from './helpers/events';
import tatiana from "./assets/img/home/foto-tatiana-lokl.png";
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Reset from './Pages/Auth/Reset';
import Verify from './Pages/Auth/Verify';
import Webinars from './Pages/Webinars';
import Blog from './Pages/Blog';
import Article from './Pages/Blog/Article';
import { setUnitPrice } from './helpers/functions';
import ConfirmationCode from './Pages/Auth/ConfirmationCode';
import ArticleHospitality from './Pages/Blog/ArticleHospitality';
import ArticlePresupuesto from './Pages/Blog/ArticlePresupuesto';
import ArticleSectores from './Pages/Blog/ArticleSectores';
import ArticleOpcionesInversion from './Pages/Blog/ArticleOpcionesInversion';
import ArticleInversionAhorro from './Pages/Blog/ArticleInversionAhorro';
import ArticleConceptosBasicosDeInversion from './Pages/Blog/ArticleConceptosBasicosDeInversion';
import ArticleInvertirEnBienesRaices from './Pages/Blog/ArticleInvertirEnBienesRaices';
import ArticleOrganizarMisFinanzas from './Pages/Blog/ArticleOrganizarMisFinanzas';
import { ArticleBody } from './Pages/Blog/ArticleBody';
import { articles } from './Pages/Blog/Articles';
import { UTMContext } from './contexts';
import { Approved } from './Pages/Payment/Approved';
import Fail from './Pages/Payment/Fail';
import { AboutUs } from './Pages/AboutUs';
import InvestorDashboard from './Pages/InvestorDashboard';
import InvestedProjectCard from './Pages/InvestorDashboard/components/InvestedProjectCard';
import ProjectDetailDashboard from './Pages/InvestorDashboard/components/ProjectDetailDashboard';
import InvestorDashboardLayout from './Pages/InvestorDashboard/components/InvestorDashboardLayout';
import RentDetailDashboard from './Pages/InvestorDashboard/components/RentDetailDashboard';
import SubscriptionDetailDashboard from './Pages/InvestorDashboard/components/SubscriptionDetailDashboard';
import ProfileDetailDashboard from './Pages/InvestorDashboard/components/ProfileDetailDashboard';
import PageNotFoundDashboard from './Pages/InvestorDashboard/components/PageNotFoundDashboard';
import { DividendReinvestment } from './Pages/InvestorDashboard/components/DividendDetail/DividendReinvestment';
import { DividendPayout } from './Pages/InvestorDashboard/components/DividendDetail';
import { DividendDashboardLayout } from './Pages/InvestorDashboard/components/DividendDashboardLayout';
import { DividendReinvestmentDetail } from './Pages/Dashboard/detail/sections/DividendReinvestmentDetail';
import { ArticleFractionalInvestmentRealEstate} from './Pages/Blog/ArticleInversionFraccionadaBienesRaices';
import Ambassadors from './Pages/Ambassadors';
import { PropertyPage } from './Pages/PropertyPage/PropertyPage';
import { PageNotFound } from './components/PageNotFound';
import RegisterAmbassador from './Pages/Auth/RegisterAmbassador';

/* const Home = lazy(() => import('./Pages/Home')); */
const NewHome = lazy(() => import('./Pages/NewHome'));
// const NidoAgua = lazy(() => import('./nidoAgua'));
/* const NidoDeAgua = lazy(() => import('./Pages/NidoDeAgua'));
const IndieUniverse = lazy(() => import('./indieUniverse')); */

function App() {
  const btnWhatsapp = () => {
    return (
      <FloatingWhatsApp
        phoneNumber="573207379535"
        accountName="Luis Felipe"
        allowEsc
        allowClickAway
        notification
        notificationSound
        avatar={tatiana}
        statusMessage="En línea"
        chatMessage="Hola! Soy Luis Felipe 😊 Tu asesor en inversiones inmobiliarias. Cuál es tu nombre?"
        placeholder="Escríbenos un mensaje"
      />
    );
  }

  const validateRedirect = () => {

    const url = new URL(window.location.href);

    const redirect = url.searchParams.get("redirect_to");

    if (redirect) {

      window.location.href = redirect;

    }


  }

  useEffect(() => {

    // consultar nuevo precio del unit
    setUnitPrice();

    // Function to clear complete cache data
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // parte de hotjar
    hotjar.initialize(2663938, 6);

    on('authed', () => {
      validateRedirect();
    });

    const url = window.location.href;
    const parameter = new URL(url);
    const code = parameter.searchParams.get('code');

    if (code && !url.includes('/payment/complete') && !url.includes('/checkout/personal-data')) {
      localStorage.setItem('referral_code', code);
    }

    const urlDashboardPattern = /^\/dashboard\/([^/]+)$/;

    if (!urlDashboardPattern.test(window.location.pathname)) {
      localStorage.removeItem("projects");
      localStorage.removeItem("name");
    }

  }, []);

  const context = useContext(UTMContext);
  const { setUTM } = context;

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utm_source = searchParams.get('utm_source') || undefined;
    const utm_medium = searchParams.get('utm_medium') || undefined;
    const utm_campaign = searchParams.get('utm_campaign') || undefined;
    const utm_term = searchParams.get('utm_term') || undefined;
    const utm_content = searchParams.get('utm_content') || undefined;

    if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
      setUTM({
        utmSource: utm_source,
        utmMedium: utm_medium,
        utmCampaign: utm_campaign,
        utmTerm: utm_term,
        utmContent: utm_content,
      });
    }

  }, [setUTM]);

  return (
    <Router>
      <Toaster
        toastOptions={{
          success: {
            style: {
              fontWeight: 'bold'
            },
          },
          error: {
            style: {
              background: '#FF0F0F',
              color: 'white',
              fontWeight: 'bold'
            }
          },
          style: {
            fontWeight: 'bold'
          },
          duration: 4000
        }}
      />
      <Suspense>

        <div>

          {/* es la barra superior para navegar por la página */}
          <Topbar />

          {btnWhatsapp()}

          <Routes>

            {/*<Route
              path='/'
              element={<Home />}
            />*/}

            <Route
              path='/'
              element={<NewHome />}
            />
            
          <Route
              path="/nido"
              element={<Navigate to="/project/nido-de-agua" replace />}
            /> 

            <Route
              path='/dashboard2'
              element={<Dashboard />}
            >

              <Route
                path=''
                element={<DashboardProjects />}

              />

              <Route
                path=':projectId'
                element={<DashboardDetail />}
              />

            </Route>

            <Route
            path='/project/:projectCode'
            element={<PropertyPage />}
            />
            <Route path="/project" element={<PageNotFound />} />
            
            <Route
              path="/dashboard"
              element={<InvestorDashboard />}
            >
              {/* Ruta principal para /dashboard */}
              <Route path="" element={<InvestedProjectCard />} />
              {/* Layout para rutas que comparten lógica */}
              <Route element={<InvestorDashboardLayout />}>
                {/* Ruta común para casos sin projectId */}
                <Route
                  path=":type"
                  element={<PageNotFoundDashboard />}
                />

                {/* Rutas dinámicas con projectId */}
                <Route path="reinvestment" element={<DividendReinvestmentDetail totalNoRequestAmount={0} />} />
                <Route path="project-detail/:projectId" element={<ProjectDetailDashboard />} />
                <Route path="rent/:projectId" element={<RentDetailDashboard />} />
                <Route path="subscription/:projectId" element={<SubscriptionDetailDashboard />} />
                <Route path="profile/:projectId" element={<ProfileDetailDashboard />} />
                <Route path="dividend/:projectId" element={<DividendDashboardLayout />}>
                  <Route index element={<Navigate to="/dashboard" replace />} />
                  <Route path="payout" element={<DividendPayout />} />
                  <Route path="reinvestment" element={<DividendReinvestment />} />
                  <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </Route>
              </Route>
            </Route>

            {/* <Route
            path="/modelo-suscripcion"
            element={<SuscriptionModel/>}
          /> */}

            <Route
              path="/cuotas-suscripcion"
              element={<Instalments />}
            />

            <Route
              path="/webinars"
              element={<Webinars />}
            />

            <Route
              path="/checkout"
              element={<Payment />}
            >

              <Route
                path='invest'
                element={<Invest />}
              />

              <Route
                path='summary-invest'
                element={<SummaryInvest />}
              />

              <Route
                path='personal-data'
                element={
                  // <DndProvider backend={HTML5Backend}>
                  <PersonalData />
                  // </DndProvider>
                }
              />

              <Route
                path='identity'
                element={
                  <DndProvider backend={HTML5Backend}>
                    <Identity />
                  </DndProvider>
                }
              />

              <Route
                path='contract'
                element={<Contract />}
              />

            </Route>

            <Route
              path="/payment"
              element={<Payment />}
            >

              <Route
                path='complete'
                element={<Complete />}
              />

              <Route
                path='successful'
                element={<Approved />}
              />

              <Route
                path='approved'
                element={<Approved />}
              />

              <Route
                path='fail'
                element={<Fail />}
              />

              <Route
                path='error'
                element={<Error />}
              />

            </Route>

            <Route
              path='/login'
              element={<Login />}
            />

            <Route
              path='/register'
              element={<Register />}
            />
            <Route
              path='/register-ambassador'
              element={<RegisterAmbassador />}
            />

            <Route
              path='/confirmation-code'
              element={<ConfirmationCode />}
            />

            <Route
              path="/reset-password"
              element={<Reset />}
            />

            <Route
              path="/new-password"
              element={<Verify />}
            />

            <Route
              path="/blog"
              element={<Blog />}
            />

            <Route
              path="/blog/tips-inversion-finanzas/conceptos-basicos"
              element={<ArticleConceptosBasicosDeInversion />}
            />

            <Route
              path="/blog/donde-invertir-colombia-2023"
              element={<Article />}
            />

            <Route
              path="/blog/inversiones-colaborativas-hospitality"
              element={<ArticleHospitality />}
            />

            <Route
              path="/blog/planificar-presupuesto-largo-plazo"
              element={<ArticlePresupuesto />}
            />

            <Route
              path="/blog/inversion-fraccionada-bienes-raices"
              element={<ArticleFractionalInvestmentRealEstate />}
            />

            <Route
              path='/aboutus'
              element={<AboutUs/>}
            />
            <Route
              path='/ambassadors'
              element={<Ambassadors/>}
            />
    
            <Route
              path="/blog/sectores-invertir-colombia-2023"
              element={<ArticleSectores />}
            />

            <Route
              path="/blog/opciones-de-inversion-colombia"
              element={<ArticleOpcionesInversion />}
            />

            <Route
              path="/blog/inversion-ahorro-crecimiento-financiero"
              element={<ArticleInversionAhorro />}
            />
            <Route
              path="/blog/invertir-en-bienes-raices"
              element={<ArticleInvertirEnBienesRaices />}
            />
            <Route
              path="/blog/organizar-mis-finanzas"
              element={<ArticleOrganizarMisFinanzas />}
            />

            {
              articles.map((article) => {
                return (
                  <Route
                    path={`/blog/${article.urlRouter}`}
                    element={
                      <ArticleBody
                        key={article.urlRouter}
                        title={article.title}
                        titleHeader={article.titleHeader}
                        descriptionHeader={article.descriptionHeader}
                        articleCards={article.articleCards}
                      />
                    }
                  />
                )
              })
            }
          <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>

          {/* es la barra inferior para navegar por la página */}

          <Footer />

        </div>

      </Suspense>

    </Router>
  );

}

export default App;
