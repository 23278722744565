import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper'; 
import 'swiper/css';
import 'swiper/css/pagination';
import { Cardsteam } from '../components/componentsTeamFolder'; 
import { motion } from 'framer-motion';


export const OurTeam: React.FC = () => {
    const teamMembers = [
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/olarte.jpg', url:"https://www.linkedin.com/in/camiloolarte1/" , name: 'Camilo Olarte', role: 'CEO' },
        /* { src: 'https://lokl-assets.s3.amazonaws.com/about-us/lau.jpg', name: 'Laura Castrillón', role: 'Líder Proyectos' }, */
        /* { src: 'https://lokl-assets.s3.amazonaws.com/about-us/arge.jpg', name: 'Argemiro Causil', role: 'Director Admin & Proyectos' }, */
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/pipe.jpg',url:"https://www.linkedin.com/in/luis-felipe-ramírez-049a08203/" ,name: 'Luis Felipe Ramírez', role: 'Líder inversiones' },
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/jhon.jpg',url:"https://www.linkedin.com/in/jhon-esteban-velasquez-gomez-a8928a346/" ,name: 'Jhon Esteban Velásquez', role: 'Líder Tech' },
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/juli.jpg', url:"https://www.linkedin.com/in/julian-rodriguez-olaya-943567265/", name: 'Julián Rodríguez', role: 'Desarrollador Full Stack' },
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/mari.jpg', url:"https://www.linkedin.com/in/camiloolarte1/", name: 'Mariana Salazar', role: 'Líder de Comunidad' },
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/yani.jpg', url:"https://www.linkedin.com/in/yaniris-acosta-907ab933a/", name: 'Yaniris Acosta', role: 'Líder Talento' },
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/yuli.jpg', url:"https://www.linkedin.com/in/yuliana-martinez-6247342a2/", name: 'Yuliana Martínez', role: 'Líder Financiera' },
        /* { src: 'https://lokl-assets.s3.amazonaws.com/about-us/cami.jpg', url:"ssa", name: 'Camilo Manco', role: 'Líder de Experiencia' }, */
        { src: 'https://lokl-assets.s3.amazonaws.com/about-us/gerald.jpg', url:"https://www.linkedin.com/in/geraldineperezdigital/", name: 'Geraldine Perez', role: 'Líder de Mercadeo' },
    ];

    const [isVisible, setIsVisible] = useState(false);
    const teamRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); 
            }
        }, {
            threshold: 0.1, 
        });

        if (teamRef.current) {
            observer.observe(teamRef.current);
        }

        return () => {
            if (teamRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(teamRef.current);
            }
        };
    }, []);

    return (
        <div className="relative w-full h-full overflow-hidden" ref={teamRef}>
            <div className="text-center my-10">
                <h2 className="text-3xl font-light primaryFont">NUESTRO EQUIPO</h2>
                <h1 className="text-5xl font-bold italic primaryFont">EXPERIMENTADO</h1>
            </div>

            <div className="w-full my-10">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{ clickable: true, el: '.custom-pagination', bulletClass: 'swiper-pagination-bullet gray-bullet' }} 
                    autoplay={{ delay: 4500, disableOnInteraction: false }} 
                    loop = {true}
                    modules={[Pagination, Autoplay]} 
                    className="mySwiper"
                    breakpoints={{
                        640: { slidesPerView: 1, spaceBetween: 50 },
                        768: { slidesPerView: 2, spaceBetween: 10 },
                        1024: { slidesPerView: 3, spaceBetween: 100 },
                    }}
                >
                    {teamMembers.map((member, index) => (
                        <SwiperSlide key={index}>
                            <motion.div 
                                initial={{ opacity: 0, scale: 0.9 }} 
                                animate={isVisible ? { opacity: 1, scale: 1 } : {}} 
                                transition={{ duration: 0.8, delay: 0.5 }}
                            >
                                <Cardsteam 
                                    src={member.src} 
                                    name={member.name} 
                                    role={member.role} 
                                    url={member.url}
                                />
                            </motion.div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};
